import React from 'react';
import nikolozi1 from "../assets/nikolozi1.jpg"
import nick from "../assets/nick.jpg"
import usaxelo from "../assets/unnamed.jpg"
import duda from "../assets/dudaa.jpg"
import anastasia from "../assets/anastasia.jpg"
import gega from "../assets/gega1.png"
import storm from "../assets/storm.png"
import elene from "../assets/elenesamsiani.jpg"
import { useTranslation } from 'react-i18next';
import AOS from "aos"
import 'aos/dist/aos.css'
import { useEffect } from 'react';

const Team = ({language}) => {
  const { t } = useTranslation();

  useEffect(()=>{
    AOS.init({duration: 2000})
  }, [])

  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20" data-aos="zoom-in-up">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>
           {t("Meet the Team Behind")}<span className='text-primary' style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "Poppins-Regular"}}> GETchmeva</span>
          </h2>
        </div>

        <div className="grid max-w-6xl grid-cols-1 px-20 mx-auto mt-12 text-center sm:px-0 sm:grid-cols-2 md:mt-20 gap-x-8 md:grid-cols-4 gap-y-12 lg:gap-x-16 xl:gap-x-20">
          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={nikolozi1}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Nikoloz Zurabiani")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Co-Founder<br />CEO</p>
          </div>

          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={nick}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Nikoloz Tchitanava")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Co-Founder<br />CTO</p>
          </div>


          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={anastasia}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Anastasia Arudashvili")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Co-founder<br />COO</p>
          </div>
          
          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={duda}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Duda Elbakidze")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Lead Designer<br />Junior Front-End Develeoper</p>
          </div>

          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={usaxelo}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("David Zilpimiani")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Management Manager</p>
          </div>

          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={gega}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Gega Tvaradze")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Lead Back-End Developer</p>
          </div>

          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={storm}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Storm Bjorn")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Senior Front-End Developer</p>
          </div>

          <div>
            <img
              className="object-cover w-32 h-32 mx-auto rounded-full lg:w-44 lg:h-44"
              src={elene}
              alt=""
            />
            <p className="mt-5 text-lg font-bold text-gray-900 sm:text-xl sm:mt-8 font-pj" style={language === 'En' ? {fontFamily: "Poppins-Regular"} : {fontFamily: "tkt"}}>{t("Elene Samsiani")}</p>
            <p className="mt-2 text-base font-normal text-gray-600 font-pj">Marketing Head</p>
          </div>

        </div>

        

      </div>
    </section>
  );
};

export default Team;
