import React, { useState, useRef, useEffect } from "react";
import { BrowserMultiFormatReader } from "@zxing/library";
import "./ScanMain.css";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import i18n from "../i18next";
import Navbar from "./Navbar";

export default function ScanMain() {
  const { t } = useTranslation();
  const [language, setLanguage] = useState("En");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [barcodeResult, setBarcodeResult] = useState(null);
  const videoRef = useRef(null);
  const codeReader = useRef(new BrowserMultiFormatReader());

  const changeLanguageFunction = () => {
    const newLanguage = language === "En" ? "Ka" : "En";
    i18n.changeLanguage(newLanguage);
    setLanguage(newLanguage);
  };

  const handleScanClick = async () => {
    setIsModalOpen(true);
    try {
      const constraints = {
        video: { facingMode: { ideal: "environment" } },
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          videoRef.current.play().catch((error) => {
            console.error("Error playing the video:", error);
          });
        };

        codeReader.current.decodeFromVideoDevice(null, videoRef.current, (result, err) => {
          if (result) {
            setBarcodeResult(result.getText());
            handleCloseModal();
          }
          if (err) {
            console.error(err);
          }
        });
      }
    } catch (error) {
      console.error("Error accessing the camera:", error);
      alert("Camera access is required to scan the product. Please enable it.");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    if (videoRef.current && videoRef.current.srcObject) {
      const stream = videoRef.current.srcObject;
      const tracks = stream.getTracks();
      tracks.forEach((track) => track.stop());
    }
    codeReader.current.reset();
  };

  useEffect(() => {
    return () => handleCloseModal();
  }, []);

  return (
    <>
      <Navbar changeLanguageFunction={changeLanguageFunction} language={language} />
      <div className="scanContainer">
        <h1 style={
            language === "En"
              ? { fontFamily: "Poppins-Regular" }
              : { fontFamily: "tkt" }
          } className="custom-heading2">
          <span style={
            language === "En"
              ? { fontFamily: "Poppins-Regular" }
              : { fontFamily: "tkt" }
          } className="text-primary">{t("Scan ")}</span>
          {t("Product Now To Get Its Insights")}
        </h1>
        <button style={
            language === "En"
              ? { fontFamily: "Poppins-Regular" }
              : { fontFamily: "tkt" }
          } className="custom-button2" onClick={handleScanClick}>
          {t("Scan The Product")}
        </button>
      </div>

      {barcodeResult && (
        <div style={
          language === "En"
            ? { fontFamily: "Poppins-Regular" }
            : { fontFamily: "tkt" }
        } className="resultContainer">
          <h2>{t("Scanned Barcode:")}</h2>
          <p>{barcodeResult}</p>
        </div>
      )}

      {isModalOpen && (
        <div className="fullscreenModal">
          <button className="closeButton" onClick={handleCloseModal}>
            &times;
          </button>
          <video
            ref={videoRef}
            className="fullscreenVideo"
            playsInline
            muted
          />
          {/* Aim Overlay with corners and line */}
          <div className="aimOverlay">
            <div className="crosshair">
              <div className="corner top-left"></div>
              <div className="corner top-right"></div>
              <div className="corner bottom-left"></div>
              <div className="corner bottom-right"></div>
              <div className="horizontal-line"></div>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
}
